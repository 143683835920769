import React, { createContext, useState, useContext } from 'react';

// Khởi tạo context với một đối tượng mặc định
export const SelectedVariantContext = createContext({
    selectedVariants: null, // Hoặc giá trị khởi tạo nào đó
    setSelectedVariants: () => { }, // Một hàm trống
    quantitys: null,
    setQuantitys: () => null,
});

export const useOrderData = () => useContext(SelectedVariantContext);

export const SelectedVariantProvider = ({ children }) => {
    const [selectedVariants, setSelectedVariants] = useState({});
    const [quantitys, setQuantitys] = useState(null)
    return (
        <SelectedVariantContext.Provider value={{ selectedVariants, setSelectedVariants, quantitys, setQuantitys }}>
            {children}
        </SelectedVariantContext.Provider>
    );
};
