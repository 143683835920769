import { BrowserRouter } from "react-router-dom";
import { createRoot } from "react-dom/client"; // Import createRoot
import { I18nextProvider } from "react-i18next";
import "antd/dist/antd";
import 'bootstrap/dist/css/bootstrap.min.css';
import Router from "./router";
import i18n from "./translation";
import { OrderDataProvider } from "./components/OrderDataProvider";
import { SelectedVariantProvider } from "./components/SelectedVariantProvider";

const App = () => (
  
  <BrowserRouter>
    <I18nextProvider i18n={i18n}>
    <OrderDataProvider>
      <SelectedVariantProvider>
        <Router />
        </SelectedVariantProvider>
      </OrderDataProvider>
    </I18nextProvider>
  </BrowserRouter>
);

const container = document.getElementById("root")!;
const root = createRoot(container);
root.render(<App />);