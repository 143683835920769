import React, { createContext, useState, useContext } from 'react';

// Khởi tạo context với một đối tượng mặc định
export const OrderDataContext = createContext({
  orderData: null, // Hoặc giá trị khởi tạo nào đó
  setOrderData: () => {}, // Một hàm trống
});

export const useOrderData = () => useContext(OrderDataContext);

export const OrderDataProvider = ({ children }) => {
  const [orderData, setOrderData] = useState(null);

  return (
    <OrderDataContext.Provider value={{ orderData, setOrderData }}>
      {children}
    </OrderDataContext.Provider>
  );
};
