
const routes = [
  {
    path: ["/", "/home"],
    exact: true,
    component: "Home",
  },
  {
    path: ["/", "/:productId"],
    exact: true,
    component: "ProductDetail",
  },
  {
    path: ["/", "/cart/checkout"],
    exact: true,
    component: "Checkout",
  },
  {
    path: ["/", "/url/not-found"],
    exact: true,
    component: "NotFound404",
  },
  {
    path: ["/", "/checkout/done"],
    exact: true,
    component: "CheckOutDone",
  },

  {
    path: ["/", "/checkout/buy-it-now"],
    exact: true,
    component: "BuyItNow",
  },
  {
    path: ["/", "/legal/policy"],
    exact: true,
    component: "PrivacyPolicy",
  },
  {
    path: ["/", "/legal/shipping"],
    exact: true,
    component: "ShippingPolicy",
  },
  {
    path: ["/", "/legal/refund"],
    exact: true,
    component: "RefundPolicy",
  },
  {
    path: ["/", "/payment/method"],
    exact: true,
    component: "PaymentMethod",
  },
  {
    path: ["/", "/resources/aboutus"],
    exact: true,
    component: "AboutUs",
  },
  {
    path: ["/", "/resources/faqs"],
    exact: true,
    component: "FAQS",
  },
  {
    path: ["/", "/tracking/order"],
    exact: true,
    component: "TrackingOrder",
  },


];

export default routes;
